import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import styled from 'styled-components';
import axios from 'axios';
import messageDescriptorSchema from '../schemas/message-descriptor';
import Alert from '../../components/atoms/Alert';

import { region } from '../localizations/current-locale';
import { dynamicBrand } from './brand';

import devices from '../styles/Devices';
import { isProduction } from './environment';

let messages;
import(`./text-resources/${dynamicBrand()}/${region}/messages`).then((m) => {
  messages = m.default;
});

const ErrorBoundaryStyled = styled.div`
  && {
    background: var(--link-color);
    align-content: center;
    display: flex;
    flex-flow: row wrap;
    height: 100vh;
  }
`;

const Headline = styled.h2`
  && {
    color: var(--white);
    flex: 1 100%;
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const ErrorBoundaryAlert = styled.div`
  && {
    margin: 0 auto;
    text-align: center;
    padding: 0 1rem;
    
    button{
      margin-top: 1rem;
      background: var(--white);
      padding: 0.5rem 3rem;
    }

    @media ${devices.upFromLargeDesktop} {
      width: 50%;
    }
  }
`;

const ErrorBoundary = (props) => {
  const {
    children,
    headline,
    intl,
  } = props;

  const alertSlack = () => {
    const webhook = `${process.env.REACT_APP_API_ROOT_ONBOARDING}/slack/notify`;
    const { href } = window.location;

    axios.post(webhook, { text: `Error occurred at: ${href}` });
  };

  const ErrorFallbackComponent = () => {
    const errorMessage = intl.formatMessage(messages.defaultErrorMessage);

    useEffect(() => {
      if (isProduction()) {
        alertSlack();
      }
    });

    return (
      <ErrorBoundaryStyled>
        <Headline>
          <h1>
            {headline
              ? intl.formatMessage(messages.headline)
              : intl.formatMessage(messages.defaultErrorHeadline)}
          </h1>
        </Headline>
        <ErrorBoundaryAlert>
          <Alert
            display={errorMessage}
            type="neutral"
          />
        </ErrorBoundaryAlert>
      </ErrorBoundaryStyled>
    );
  };

  const getChildren = () => children;

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallbackComponent}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {getChildren()}
    </ReactErrorBoundary>
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  headline: PropTypes.oneOfType([
    PropTypes.string,
    messageDescriptorSchema,
  ]),
  intl: PropTypes.shape().isRequired,
};

ErrorBoundary.defaultProps = {
  children: '',
  headline: '',
};

export default (injectIntl(ErrorBoundary));
