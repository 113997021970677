import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import { setContextValue } from '../../../js/store/actions/api/set-context-value';
import intlShape from '../../../js/schemas/intl';
import tooltipToObject from '../../../js/helpers/tooltipToObject';
import Tooltips from '../../utils/tooltips';
import style from './style';
import { brand } from '../../../js/localizations/current-locale';

const TitleWrapper = styled.div`
  && {
    justify-content: ${(props) => (props.layout === 'toggle' ? 'center' : 'flex-start')};
    display: flex;
    color: ${style.nonSelectedColor};
    align-items: center;
    position: relative;

    label {
      font-weight: ${style.buttonGroupFontWeight};
      font-size: 1rem;
      text-align: ${(props) => (props.textAlign !== 'center' ? 'left' : 'center')};
      width: ${style.labelWidth};
    }
  }
`;

const CheckMark = styled.i`
  && {
    margin-right: 0.5rem;
    color: ${style.checkMarkColor};
  }
`;

const ButtonWrapper = styled.div`
&& {
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) => (props.layout === 'compact' ? 'repeat(6, 1fr)' : 'repeat(3, 1fr)')};
  grid-template-columns: ${(props) => (props.layout === 'toggle' ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)')};
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  font-size: 1rem;
  margin: 0.5rem 0 2rem 0;
  word-break: break-word;

  @media screen and (min-width : 32rem) and (max-width : 62rem) {
    grid-template-columns: ${(props) => (props.layout === 'compact' ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)')};
    grid-template-columns: ${(props) => (props.layout === 'toggle' ? 'repeat(2, 1fr)' : 'repeat(2, 1fr)')};
  }
  @media screen and (min-width : 0rem) and (max-width : 32rem) {
    grid-template-columns: ${(props) => (props.layout === 'compact' ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)')};
    grid-template-columns: ${(props) => (props.layout === 'toggle' ? style.toggleTabletLayout : style.toggleTabletLayout)};
  }
}
`;

const StyledButton = styled.button`
&&{
  position: relative;
  background: ${style.nonSelectedBackgroundColor};
  cursor: pointer;
  color: ${style.nonSelectedColor};
  min-height: 2.5rem;
  min-width: 1px;
  border-radius: 3px;
  justify-content: center;
  border: 2px solid transparent;
  text-align: center;
  box-shadow: ${style.boxShadow};
  transition: 0.3s all ease;
}
`;

const SelectedButton = styled.div`
&& {
  background: ${style.selectedButtonBackground};
  color: ${style.selectedButtonColor};
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline-color: var(--teal);
  border: inherit;
  border-width: inherit;
  border-radius: inherit;
  border-color: ${style.selectedBorderColor};
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
}
`;

const ButtonGroup = ({
  sectionName,
  name,
  buttonLayout,
  labelLayout,
  buttons,
  tooltip,
  tagline,
  label,
  intl: { formatMessage },
}) => {
  const dispatch = useDispatch();
  const updateValue = (...args) => dispatch(setContextValue(...args));
  const changeField = (...args) => change('application', ...args);
  const formState = useSelector((state) => state.form.application.values);
  const boolify = (value) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      default:
        return value;
    }
  };

  const isDigifinans = brand === 'digifinans';
  const isRahoitu = brand === 'rahoitu';

  const handleClick = (name, value) => {  //eslint-disable-line
    const formatedVal = boolify(value);
    dispatch(updateValue({ context: name, value: formatedVal }));
    dispatch(changeField(name, formatedVal));
  };

  const isActive = (value) => {
    if (sectionName) {
      const field = name.split('.');
      return String(formState[field[0]][field[1]]) === value;
    }
    return String(formState[name]) === value;
  };

  const tooltipObject = tooltipToObject(tooltip);
  if (typeof tooltip === 'object') { tooltipObject.text = formatMessage(tooltip.text); }

  return (
    <div>
      <TitleWrapper
        layout={buttonLayout}
        textAlign={labelLayout}
      >

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gridGap: '0.5rem',
          }}
        />

        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        {label && <label>{formatMessage(label)}</label>}
        { tooltipObject.text && (
          <Tooltips tooltipObject={tooltipObject} />
        )}
      </TitleWrapper>
      <TitleWrapper
        layout={buttonLayout}
        textAlign={labelLayout}
      >
        {tagline && <small>{formatMessage(tagline)}</small>}
      </TitleWrapper>

      <ButtonWrapper layout={buttonLayout}>
        {buttons.map((item) => (
          <StyledButton
            id={item.value}
            key={item.value}
            onClick={() => handleClick(name, item.value)}
            tabindex="0"
            type="button"
          >
            {/* Something with rehydrate makes this neccecary */}
            { isActive(item.value) ? (
              <>
                <SelectedButton>
                  {(!isDigifinans && !isRahoitu) && <CheckMark>✓</CheckMark>}
                  {' '}
                  {formatMessage(item.label)}
                </SelectedButton>
                <CheckMark>✓</CheckMark>
                {' '}
                {formatMessage(item.label)}
              </>
            ) : (
              <>{formatMessage(item.label)}</>
            )}
          </StyledButton>
        ))}
      </ButtonWrapper>
    </div>
  );
};

ButtonGroup.propTypes = {
  name: PropTypes.string,
  sectionName: PropTypes.string,
  tooltip: PropTypes.shape(),
  buttonLayout: PropTypes.string,
  labelLayout: PropTypes.string,
  tagline: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape()),
  label: PropTypes.shape(),
  intl: intlShape.isRequired,
};

ButtonGroup.defaultProps = {
  name: '',
  sectionName: '',
  tooltip: {},
  buttonLayout: '',
  labelLayout: '',
  tagline: '',
  buttons: [],
  label: {},
};

const ButtonGroupWithIntl = injectIntl(ButtonGroup);

export { ButtonGroupWithIntl as ButtonGroup };

export default ButtonGroupWithIntl;
