import * as Endpoints from '../constants/Endpoints';
import { FetchError, PermissionDeniedError } from '../constants/Errors';
import { getMimeType } from './contentType';

let urlETags = {};

const clearEtags = () => {
  urlETags = {};
};

export const getRequestObject = (url, method = 'GET', body = undefined, headers = new Headers()) => {
  const parameters = {
    method,
    headers,
    mode: 'cors',
  };

  if (body && typeof body === 'object') {
    parameters.headers.append('Content-Type', 'application/json');
    parameters.body = JSON.stringify(body);
  }
  return new Request(url, parameters);
};

export const makeRequest = (options = {}) => {
  const url = options.endpoint.createUrl(options.urlParams);
  const EndpointError = options.endpoint.error;
  const requestHeaders = new Headers(options.headers || {});

  if (urlETags[url]) {
    requestHeaders.append('If-None-Match', urlETags[url]);
    requestHeaders.append('Cache-Control', 'private, must-revalidate');
  }

  const requestObject = getRequestObject(url, options.method, options.body, requestHeaders);

  return fetch(requestObject)
    .catch(() => { throw new EndpointError(); })
    .then((response) => {
      if (response.status === 401) {
        throw new PermissionDeniedError();
      }
      if (response.status >= 400) {
        const fetchError = new FetchError();
        fetchError.status = response.status;

        return Promise.resolve(response.json().then((body) => {
          fetchError.type = body.details?.error?.name;
          throw fetchError;
        }));
      }
      if (response.headers && response.headers.has('ETag')) {
        urlETags[url] = response.headers.get('ETag');
      }

      if (response.headers && response.headers.has('Content-type')) {
        const contentTypeHeader = response.headers.get('Content-type');
        const mimeType = getMimeType(contentTypeHeader);

        switch (mimeType) {
          case 'application/json':
            return response.json();
          default:
            return null;
        }
      }

      return null;
    });
};

export const startLoginSigning = (payload) => {
  clearEtags();
  return makeRequest({
    method: 'POST',
    endpoint: Endpoints.START_SIGNING,
    urlParams: { type: payload.type, refId: payload.refID },
    body: payload,
  });
};

export const loginSigningStatus = (token) => (
  makeRequest({
    endpoint: Endpoints.SIGNING_STATUS,
    urlParams: { token },
  })
);

export const userLoginCancel = (token) => (
  makeRequest({
    method: 'GET',
    endpoint: Endpoints.SIGNING_CANCEL,
    urlParams: { token },
  })
);
