export const getCookie = (cname) => {
  if (typeof document === 'undefined') return '';
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (cname, cvalue, exdays) => {
  if (typeof document === 'undefined') return '';
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
  return cvalue;
};

export const isCookieSet = (cookieName) => {
  if (typeof document === 'undefined') return false;
  return Boolean(getCookie(cookieName));
};
